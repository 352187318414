import React, { lazy } from 'react';

const ProductListing = lazy(() =>
  import('./ProductListing').then(module => ({
    default: module.ProductListing,
  })),
);

const ProductModulesListing = lazy(() =>
  import('./ProductModulesListing').then(module => ({
    default: module.ProductModulesListing,
  })),
);

const ProductRoutes = [
  {
    props: { exact: true, path: '/product' },
    component: <ProductListing />,
  },
  {
    props: { exact: true, path: '/product/modules' },
    component: <ProductModulesListing />,
  },
  {
    props: { exact: true, path: '/product/:productID/modules' },
    component: <ProductModulesListing />,
  },
];

export default ProductRoutes;

import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink, Observable } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import { createUploadLink } from 'apollo-upload-client';
import jwtDecode from 'jwt-decode';
import { getAccessToken, setAccessToken } from './AccessToken';
import './index.scss';

const cache = new InMemoryCache({});
console.log('PROCESS REACT_APP_LOCAL_DB:', process.env.REACT_APP_LOCAL_DB);
console.log('NODE_ENV:', process.env.NODE_ENV);

export const SUBSCRIPTION_URL =
  process.env.REACT_APP_LOCAL_DB === 'local'
    ? 'http://localhost:5000/subscriptionx'
    : process.env.REACT_APP_LOCAL_DB === 'internal'
    ? 'https://accountx-internal-api.ifca.io/accountx'
    : process.env.REACT_APP_LOCAL_DB === 'azure'
    ? 'https://subscriptionx-dev-api.ifca.io/subscriptionx'
    : process.env.REACT_APP_LOCAL_DB === 'uat'
    ? 'https://subscriptionx-api.ifca.io/subscriptionx'
    : process.env.REACT_APP_LOCAL_DB === 'sehsa'
    ? 'http://172.17.10.95:76/subscriptionx'
    : process.env.NODE_ENV === 'development'
    ? 'http://localhost:5000/subscriptionx'
    : 'https://subscriptionx-api.ifca.asia/subscriptionx';

export const SUBSCRIPTION_REFRESH_TOKEN_URL =
  process.env.REACT_APP_LOCAL_DB === 'local'
    ? 'http://localhost:5000/refresh_token'
    : process.env.REACT_APP_LOCAL_DB === 'internal'
    ? 'https://accountx-internal-api.ifca.io/refresh_token'
    : process.env.REACT_APP_LOCAL_DB === 'azure'
    ? 'https://subscriptionx-dev-api.ifca.io/refresh_token'
    : process.env.REACT_APP_LOCAL_DB === 'uat'
    ? 'https://subscriptionx-api.ifca.io/refresh_token'
    : process.env.REACT_APP_LOCAL_DB === 'sehsa'
    ? 'http://172.17.10.95:76/refresh_token'
    : process.env.NODE_ENV === 'development'
    ? 'http://localhost:5000/refresh_token'
    : 'https://subscriptionx-api.ifca.asia/refresh_token';

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle: any;
      Promise.resolve(operation)
        .then(operation => {
          const accessToken = getAccessToken();
          if (accessToken) {
            operation.setContext({
              headers: {
                authorization: `bearer ${accessToken}`,
              },
            });
          }
        })
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    }),
);

const uploadLink = createUploadLink({
  uri: SUBSCRIPTION_URL,
  credentials: 'include',
});

export const SubscriptionClient: any = new ApolloClient({
  link: ApolloLink.from([
    new TokenRefreshLink({
      accessTokenField: 'accessToken',
      isTokenValidOrUndefined: () => {
        const token = getAccessToken();

        if (!token) return true;

        try {
          const { exp } = jwtDecode(token);
          if (Date.now() >= exp * 1000) return false;
          else return true;
        } catch (err) {
          console.log(err);
          return false;
        }
      },
      fetchAccessToken: () => {
        return fetch(SUBSCRIPTION_REFRESH_TOKEN_URL, {
          method: 'POST',
          credentials: 'include',
        });
      },
      handleFetch: accessToken => {
        setAccessToken(accessToken);
      },
      handleResponse: () => {},
      handleError: err => {
        console.warn('Your refresh token is invalid. Try to relogin');
        console.error(err);
      },
    }),
    onError(({ graphQLErrors, networkError }) => {
      console.log('graphQLErrors', graphQLErrors);
      console.log('networkError', networkError);
    }),
    requestLink,
    uploadLink,
    // new HttpLink({
    //   uri: SUBSCRIPTION_URL,
    //   credentials: 'include',
    // }),
  ]),
  cache,
});
